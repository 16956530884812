import React, { useState, useRef } from 'react';
import type { WPublicWeddingView, WRsvpData } from '@zola/svc-web-api-ts-client';

import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import { EMAIL_REGEX } from '@zola/zola-ui/src/components/Form/util/validations';

import { useAppSelector } from '~/reducers/useAppSelector';
import { useAppDispatch } from '~/reducers/useAppDispatch';
import { rsvpConfirmation } from '~/actions/PublicWebsiteActions';
import {
  getGuestGroupRsvp,
  getPublicWebsiteWedding,
} from '~/selectors/public/publicWebsiteSelectors';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { mapFontColorToBgOverlayWithOpacity } from '~/components/publicWebsiteV2/util/mappers';

import { isDarkColor } from '~/pages/publicStyleUtils/utils.styles';
import {
  Container,
  Title,
  Form,
  InputContainer,
  StyledCheckbox,
  SubText,
  StyledLink,
  buttonStyles,
} from './EmailForm.styles';
import { StyledCheckmarkIcon } from '../../GuestRsvpSummary.styles';

const EmailForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const guestGroupRsvp: WRsvpData = useAppSelector(getGuestGroupRsvp);
  const publicWedding: WPublicWeddingView = useAppSelector(getPublicWebsiteWedding);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [subscribe, setSubscribe] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [containerHeight, setContainerHeight] = useState<number>();
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    state: {
      components: {
        cmsEntityComponentBodyFontValues,
        styleCmsEntityBodyFont,
        globalAccentColor,
        ThemedButton,
        CmsEntityBodyLink,
        globalInputFieldStyles,
        backgroundColors,
      },
    },
  } = useWebsiteThemeContext();

  const handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { guest_group_uuid } = guestGroupRsvp;
    const { wedding_account_uuid } = publicWedding;
    // maintain height for email confirmation message div
    setContainerHeight(ref.current?.clientHeight);

    dispatch(rsvpConfirmation({ guest_group_uuid, wedding_account_uuid, email, subscribe }))
      .then(() => setSubmitted(true))
      .catch((err) => {
        throw new Error(err.message || 'Error request RSVP email confirmation');
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setEmail(value);
    setIsEmailValid(EMAIL_REGEX.test(value));
  };

  const handleCheckboxClick = () => setSubscribe(!subscribe);

  const handleSendAnother = () => {
    setEmail('');
    setSubscribe(true);
    setSubmitted(false);
  };

  const StyledTitle = styleCmsEntityBodyFont(Title);
  const StyledLinkInline = styleCmsEntityBodyFont(StyledLink);

  return (
    <Container
      ref={ref}
      containerHeight={containerHeight}
      style={{
        backgroundColor: mapFontColorToBgOverlayWithOpacity(cmsEntityComponentBodyFontValues.color),
      }}
    >
      {submitted ? (
        <>
          <StyledTitle>
            <StyledCheckmarkIcon height={24} width={24} color={globalAccentColor} /> A copy of your
            RSVP was sent to your email.
          </StyledTitle>
          <StyledLinkInline
            arrow
            colorOverride={globalAccentColor}
            role="button"
            onClick={handleSendAnother}
            sizes="small"
          >
            Send another copy to a different email
          </StyledLinkInline>
        </>
      ) : (
        <>
          <StyledTitle>Want an email copy of your RSVP?</StyledTitle>
          <Form onSubmit={handleEmailSubmit} css={globalInputFieldStyles}>
            <InputContainer>
              <InputFieldV3
                id="guest-rsvp-summary-email"
                name="email"
                placeholder="your_email@gmail.com"
                onChange={handleChange}
                value={email}
                isControlled
              />
            </InputContainer>
            <ThemedButton type="submit" disabled={!isEmailValid} css={buttonStyles}>
              Submit
            </ThemedButton>
          </Form>
          <StyledCheckbox
            isDarkMode={isDarkColor(backgroundColors.content)}
            bodyFontColor={cmsEntityComponentBodyFontValues.color}
            onChange={handleCheckboxClick}
            checked={subscribe}
            id="guest-rsvp-summary-subscribe"
            label="Send me wedding updates, special offers, and more *"
          />
          <SubText
            style={{
              fontFamily: cmsEntityComponentBodyFontValues.fontFamily,
              color: `#${cmsEntityComponentBodyFontValues.color}`,
            }}
          >
            * By checking this box, you are accepting our{' '}
            <CmsEntityBodyLink href="/terms" target="_blank" rel="noreferrer noopener">
              Terms of Use
            </CmsEntityBodyLink>{' '}
            and{' '}
            <CmsEntityBodyLink href="/privacy" target="_blank" rel="noreferrer noopener">
              Privacy Policy
            </CmsEntityBodyLink>
          </SubText>
        </>
      )}
    </Container>
  );
};

export default EmailForm;
