import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { MEDIA_QUERY, SPACING, FONT, COLORS } from '@zola/zola-ui/src/styles/emotion';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { FinalFormRadioField } from '@zola/zola-ui/src/components/Form/RadioFieldV2/FinalFormRadioField';
import {
  StyledLabel,
  SelectorLabel,
  StyledInput,
} from '@zola/zola-ui/src/components/Form/RadioFieldV2/RadioField/RadioFieldV2.styles';
import FinalFormDropdown from '@zola/zola-ui/src/components/Form/dropdownV3/FinalFormDropdown';
import RsvpListRow from '~/components/publicWebsiteV2/pages/Rsvp/components/RsvpListRow';
import { DARK_ERROR } from '~/components/publicWebsiteV2/util/colorSystem';

export const EventsLength = styled.p<{ withSpacing?: boolean }>`
  margin-bottom: 0;

  ${({ withSpacing }) =>
    withSpacing &&
    `
      margin-bottom: ${SPACING.LG};
    `};
`;

export const ButtonContainer = styled.div`
  margin-top: ${SPACING.LG};
`;

export const RsvpContainer = styled.div`
  margin-top: ${SPACING.LG};
`;

export const NoRsvpContainer = styled.div`
  padding-top: ${SPACING.LG};
  border-top: 1px solid;
`;

export const StyledRadio = styled(FinalFormRadioField)<{ isDarkMode?: boolean }>`
  &:last-of-type {
    margin-left: ${SPACING.MD};
  }

  ${StyledLabel} {
    display: flex;
    flex-flow: row;
    align-items: center;
    // Defensive styling
    text-transform: none;
    font-size: inherit;
    margin-bottom: 0;
    letter-spacing: unset;
  }

  ${({ isDarkMode }) => `
    &:hover {
      ${StyledLabel}::before {
        border-color: ${isDarkMode ? COLORS.ALTO : COLORS.EMPEROR};
      }
      ${SelectorLabel} {
        color: ${isDarkMode ? COLORS.WHITE : COLORS.EMPEROR}
      }
    }
    ${SelectorLabel} {
      color: ${isDarkMode ? COLORS.WHITE : COLORS.EMPEROR}
    }
    ${StyledInput}:checked+label::after {
      border-color: ${isDarkMode ? COLORS.ALTO : COLORS.EMPEROR};
      background-color: ${COLORS.EMPEROR};
    }
  `}
`;

export const sharedFormSpacing = css`
  margin-bottom: ${SPACING.SM};
`;

export const StyledDropdown = styled(FinalFormDropdown)`
  text-align: left;
  ${sharedFormSpacing}

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLORS.SCREAMING_GREY};
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${COLORS.SCREAMING_GREY};
  }
`;

export const InputContainer = styled.div`
  ${sharedFormSpacing}
`;

export const QuestionsContainer = styled.div`
  padding: ${SPACING.S32} 100px;
  max-width: 800px;
  margin: ${SPACING.LG} auto 0;
  border-radius: 20px;

  ${MEDIA_QUERY.MOBILE} {
    padding: ${SPACING.S32};
  }
`;

export const QuestionsText = styled.p`
  margin-bottom: ${SPACING.S24};
`;

export const Question = styled.li`
  text-align: left;

  textarea {
    height: 52px;
  }
`;

export const BackButton = styled.div`
  height: 96px;
  display: flex;
  justify-content: center;

  ${MEDIA_QUERY.MOBILE} {
    height: 60px;
  }
`;

export const StyledLink = styled(LinkV2, {
  shouldForwardProp: (prop) => prop !== 'colorOverride',
})<Partial<{ colorOverride: string }>>`
  font-size: ${FONT.FONT_SIZE_SMALL}!important;
  ${({ colorOverride }) => `
    color: #${colorOverride}!important;

    &:hover {
      color: #${colorOverride};
    }
  `}
`;

export const StyledRsvpListRow = styled(RsvpListRow)`
  ${MEDIA_QUERY.MOBILE} {
    align-items: flex-start;
  }
`;

export const EditName = styled.button`
  outline: none;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  margin-left: ${SPACING.SM};
`;

export const GuestName = styled.span`
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    margin-bottom: ${({ theme }) => theme.SPACING.S24};
  }
`;

export const Asterisk = styled.span<{ isDarkMode: boolean }>`
  color: ${({ isDarkMode }) => (isDarkMode ? DARK_ERROR : COLORS.SHIRAZ_RED)};
`;

export const FormWizardContainer = styled.div`
  text-align: center;
`;
