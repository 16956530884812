import styled from '@emotion/styled';

import { MEDIA_QUERY, SPACING, FONT, COLORS } from '@zola/zola-ui/src/styles/emotion';
import { CheckmarkCircleIcon } from '@zola/zola-ui/src/components/SvgIcons/CheckmarkCircle';
import { RemoveIcon } from '@zola/zola-ui/src/components/SvgIcons/Remove';
import RsvpListRow from '~/components/publicWebsiteV2/pages/Rsvp/components/RsvpListRow';

const SPACING_60 = '60px';

export const Title = styled.h1`
  margin-bottom: ${SPACING_60};
`;

export const SubTitle = styled.h2`
  margin: ${SPACING_60} 0;
`;

export const EventsContainer = styled.div`
  margin-bottom: ${SPACING_60};
`;

export const EventContainer = styled.div`
  & + & {
    margin-top: ${SPACING_60};
  }
`;

export const Guests = styled.ul`
  margin-top: ${SPACING.LG};
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALL)}; // intentionally FONT_SIZE_SMALL
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING.SM};
`;

export const StyledCheckmarkIcon = styled(CheckmarkCircleIcon, {
  shouldForwardProp: (prop) => prop !== 'isDarkMode',
})<Partial<{ isDarkMode: boolean }>>`
  ${({ isDarkMode }) =>
    isDarkMode
      ? `
    background-color: ${COLORS.EVERGREEN};
    color: ${COLORS.WHITE};
    border-radius: 50%;
  `
      : `
    color: ${COLORS.EVERGREEN};
  `};
  margin-right: ${SPACING.XS};
`;

export const StyledRemoveIcon = styled(RemoveIcon, {
  shouldForwardProp: (prop) => prop !== 'isDarkMode',
})<Partial<{ isDarkMode: boolean }>>`
  ${({ isDarkMode }) =>
    isDarkMode
      ? `
    background-color: ${COLORS.SHIRAZ_RED};
    color: ${COLORS.WHITE};
    border-radius: 50%;
  `
      : `
    color: ${COLORS.SHIRAZ_RED};
  `};
  margin-right: ${SPACING.XS};
  padding: 6px;
`;

export const StyledRsvpListRow = styled(RsvpListRow)`
  align-items: flex-start;

  ${MEDIA_QUERY.MOBILE} {
    flex-direction: row;
  }
`;
