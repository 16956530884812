import React, { useState } from 'react';
import type { WGuestRsvpView } from '@zola/svc-web-api-ts-client';
import { REQUIRED } from '@zola/zola-ui/src/components/Form/util/validations';

import FormInput from '@zola/zola-ui/src/components/Form/inputV3/FinalFormInput';
import { EditIcon } from '@zola/zola-ui/src/components/SvgIcons/EditIcon';
import { Condition } from '~/components/common/form/FinalFormV2Elements';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { RsvpAttendingType } from '~/components/publicWebsiteV2/pages/Rsvp/types';

import type { RsvpFormValues } from '~/components/publicWebsiteV2/pages/Rsvp/types';

import { isDarkColor } from '~/pages/publicStyleUtils/utils.styles';
import {
  InputContainer,
  StyledRadio,
  StyledDropdown,
  StyledRsvpListRow,
  EditName,
  Asterisk,
  GuestName,
} from '../GuestRsvpForm.styles';

const validateAttendingInput = (value?: string) => {
  if (value === 'NO_RESPONSE' || !value) {
    return 'Required';
  }
  return undefined;
};

const validateMealOption = (value?: string) => {
  if (!value) {
    return 'Required';
  }
  return undefined;
};

type GuestRsvpProps = {
  guest: WGuestRsvpView;
  index: number;
  eventId: number;
  formValues?: RsvpFormValues;
  mealOptions?: { value?: number; label?: string }[];
};

const GuestRsvp = ({
  guest,
  formValues,
  index,
  eventId,
  mealOptions = [],
}: GuestRsvpProps): JSX.Element => {
  const [nameWasUnknown] = useState(!guest.first_name); // persists the initial state so we know this guest was unknown
  const [isEditing, setIsEditing] = useState(false);

  const {
    state: {
      components: { styleCmsEntityBodyFont, backgroundColors },
    },
  } = useWebsiteThemeContext();

  const isDarkMode = isDarkColor(backgroundColors.content);
  const firstName: string = guest.first_name || formValues?.guests?.[index].first_name || '';
  const familyName: string = guest.family_name || formValues?.guests?.[index].family_name || '';
  const eventIndex = guest?.event_invitations?.findIndex(
    (invitation) => invitation?.event_id === eventId
  );

  const handleEditNameClick = () => setIsEditing(true);

  const StyledGuestName = styleCmsEntityBodyFont(GuestName);

  return (
    <StyledRsvpListRow key={guest.id} className="IcForceHideInputElements">
      <StyledGuestName>
        {firstName ? `${firstName} ${familyName}` : 'Guest Name Unknown'}
        <Asterisk isDarkMode={isDarkMode}> *</Asterisk>
        {nameWasUnknown && formValues?.guests?.[index].first_name && !isEditing && (
          <Condition
            when={`guests[${index}].event_invitations[${eventIndex}].rsvp_type`}
            is={RsvpAttendingType.ATTENDING}
          >
            <EditName onClick={handleEditNameClick}>
              <EditIcon height={20} width={20} />
            </EditName>
          </Condition>
        )}
      </StyledGuestName>
      <div>
        <div>
          <StyledRadio
            id={`guests-${index}-event_invitations-${eventIndex}-attending-input`}
            name={`guests[${index}].event_invitations[${eventIndex}].rsvp_type`}
            label="Will Attend"
            value={RsvpAttendingType.ATTENDING}
            inline
            validate={validateAttendingInput}
            isDarkMode={isDarkMode}
          />
          <StyledRadio
            id={`guests-${index}-event_invitations-${eventIndex}-declined-input`}
            name={`guests[${index}].event_invitations[${eventIndex}].rsvp_type`}
            label="Will Not Attend"
            value={RsvpAttendingType.DECLINED}
            inline
            validate={validateAttendingInput}
            isDarkMode={isDarkMode}
          />
        </div>

        <Condition
          when={`guests[${index}].event_invitations[${eventIndex}].rsvp_type`}
          is={RsvpAttendingType.ATTENDING}
        >
          <>
            {!!mealOptions.length && (
              <StyledDropdown
                id={`guest-form-guest-${index}-meal-option`}
                name={`guests[${index}].event_invitations[${eventIndex}].meal_option_id`}
                options={mealOptions}
                placeholder="Select Meal Option..."
                validate={validateMealOption}
              />
            )}
            {(!firstName || isEditing) && (
              <>
                <InputContainer>
                  <FormInput
                    id={`guest-form-guest-${index}-first-name`}
                    name={`guests[${index}].first_name`}
                    placeholder="First Name"
                    isRequired
                    validate={[REQUIRED]}
                  />
                </InputContainer>
                <InputContainer>
                  <FormInput
                    id={`guest-form-guest-${index}-family-name`}
                    name={`guests[${index}].family_name`}
                    placeholder="Last Name"
                    isRequired
                    validate={[REQUIRED]}
                  />
                </InputContainer>
              </>
            )}
          </>
        </Condition>
      </div>
    </StyledRsvpListRow>
  );
};

export default GuestRsvp;
