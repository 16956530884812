import React from 'react';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';

import { Row } from './RsvpListRow.styles';

type RsvpListRowProps = {
  className?: string;
};

const RsvpListRow: React.FC<RsvpListRowProps> = ({ className, children }) => {
  const {
    state: {
      components: { cmsEntityComponentBodyFontValues },
    },
  } = useWebsiteThemeContext();

  const { color: borderColor } = cmsEntityComponentBodyFontValues;

  return (
    <Row className={className} borderColor={borderColor}>
      {children}
    </Row>
  );
};

export default RsvpListRow;
