import type { WCmsPageImageView } from '@zola/svc-web-api-ts-client';

// mobile has old clients that won’t use this function and will continue to use header_image_url
export const getNonHomeCmsHeroImg = (images?: WCmsPageImageView[]) => {
  return images?.find(
    (i) =>
      i.type === (('HERO' as unknown) as WCmsPageImageView.TypeEnum) &&
      i.size === (('FREEFORM' as unknown) as WCmsPageImageView.SizeEnum)
  )?.image_url;
};

export default getNonHomeCmsHeroImg;
