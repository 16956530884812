import React, { useRef, useEffect } from 'react';
import { withTypes, Field, FormRenderProps } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import type ReCAPTCHAProps from 'react-google-recaptcha';
import { getCookie } from '@zola-helpers/client/dist/es/util/storage';

import FormInput from '@zola/zola-ui/src/components/Form/inputV3/FinalFormInput';
import FormTextarea from '@zola/zola-ui/src/components/Form/TextareaFieldV2/TextareaFieldV2Adapter';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';

import { REQUIRED, MAX_LENGTH_250 } from '@zola/zola-ui/src/components/Form/util/validations';
import { composeValidators } from '@zola/zola-ui/src/components/Form/util/composeValidators';

import { useAppSelector } from '~/reducers/useAppSelector';
import { useAppDispatch } from '~/reducers/useAppDispatch';
import { createRsvpRequestV2, hideAccessForm } from '~/actions/PublicWebsiteActions';
import { getGuestRsvpRequested } from '~/selectors/public/publicWebsiteSelectors';

import { mapFontColorToBgOverlayWithOpacity } from '~/components/publicWebsiteV2/util/mappers';
import scrollViewTo from '~/util/scrollViewTo';

import { Container, FormFieldContainer, Title, StyledLink } from './GuestRequestRsvpAccess.styles';

type AccessFormValues = {
  first_name: string;
  family_name: string;
  email_address: string;
  message?: string;
};

const GuestRequestRsvpAccess = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const guestRequestSubmitted = useAppSelector(getGuestRsvpRequested);
  const recaptchaRef = useRef<ReCAPTCHAProps>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const {
    state: {
      components: {
        ThemedButton,
        cmsEntityComponentBodyFontValues,
        styleCmsEntityBodyFont,
        styleCmsEntityHeaderFont,
        globalAccentColor,
        globalInputFieldStyles,
      },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const { Form: FinalForm } = withTypes<AccessFormValues>();

  const handleRequest = async (values: AccessFormValues) => {
    // Get captcha token and reset the instance
    let captchaToken: string | null = null;
    if (recaptchaRef && recaptchaRef.current) {
      captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
    }

    const payload = {
      ...values,
      captchaToken,
    };

    dispatch(createRsvpRequestV2(payload)).catch((err: Error) => {
      throw new Error(err?.message || 'There was an error submitting your request');
    });
  };

  const handleHideAccessForm = () => dispatch(hideAccessForm());

  // Determine if we should load captcha
  const isAutomatedTest = getCookie('automatedTest') === 'true';
  const showRecaptcha = !isAutomatedTest;

  useEffect(() => {
    if (!inPreview) scrollViewTo({ el: containerRef.current, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StyledTitle = styleCmsEntityHeaderFont(Title);
  const P = styleCmsEntityBodyFont('p');
  const StyledLinkInline = styleCmsEntityBodyFont(StyledLink);

  return (
    <Container ref={containerRef}>
      <StyledLinkInline
        arrow
        arrowPosition="prefix"
        colorOverride={globalAccentColor}
        role="button"
        onClick={handleHideAccessForm}
      >
        Back
      </StyledLinkInline>
      <StyledTitle>RSVP Request</StyledTitle>
      {!guestRequestSubmitted ? (
        <FinalForm onSubmit={handleRequest}>
          {({ handleSubmit, pristine, invalid }: FormRenderProps): JSX.Element => (
            <form
              onSubmit={(e) => {
                handleSubmit(e)?.catch(() => undefined);
              }}
              css={globalInputFieldStyles}
            >
              <FormFieldContainer
                style={{
                  backgroundColor: mapFontColorToBgOverlayWithOpacity(
                    cmsEntityComponentBodyFontValues.color
                  ),
                }}
              >
                <FormInput
                  name="first_name"
                  id="request-access-first"
                  label="First name"
                  isRequired
                  validate={[REQUIRED]}
                />
                <FormInput
                  name="family_name"
                  id="request-access-last"
                  label="Last name"
                  isRequired
                  validate={[REQUIRED]}
                />
                <FormInput
                  name="email_address"
                  id="request-access-email"
                  label="Email address"
                  isRequired
                  validate={[REQUIRED]}
                />
                <Field
                  name="message"
                  id="request-access-message"
                  label="Note to the couple (Optional)"
                  validate={composeValidators(MAX_LENGTH_250)}
                  maxChars={250}
                  component={FormTextarea}
                />
              </FormFieldContainer>
              <ThemedButton type="submit" disabled={invalid || pristine}>
                Submit request
              </ThemedButton>
            </form>
          )}
        </FinalForm>
      ) : (
        <P>Look out for an email from Zola with more information.</P>
      )}
      {showRecaptcha && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lf91DcfAAAAACNVXd1VdLe-Ty6IWlGHvHZ7jRmk"
          size="invisible"
        />
      )}
    </Container>
  );
};

export default GuestRequestRsvpAccess;
