import React, { useEffect, useRef } from 'react';

import type { WGuestGroupSearchResult } from '@zola/svc-web-api-ts-client';

import RsvpListRow from '~/components/publicWebsiteV2/pages/Rsvp/components/RsvpListRow';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { PageAnimationWrapper } from '~/components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';

import { useAppSelector } from '~/reducers/useAppSelector';

import {
  getRsvpSearchEnabled,
  getRsvpSearchResults,
} from '~/selectors/public/publicWebsiteSelectors';

import formatGuestSearchResults from '~/components/publicWebsiteV2/util/formatGuestSearchResults';
import formatGuest from '~/components/publicWebsiteV2/util/formatGuest';
import scrollViewTo from '~/util/scrollViewTo';

import {
  Above,
  Below,
  Container,
  BelowContainer,
  Guests,
  Guest,
} from './GuestSearchResults.styles';

type GuestSearchResultsProps = {
  haveSearched: boolean;
  setErrorMsg: (msg: string) => void;
  onSelectGuest: (uuid: string) => void;
  searchDisabled?: boolean;
};

const GuestSearchResults = ({
  haveSearched,
  setErrorMsg,
  onSelectGuest,
  searchDisabled,
}: GuestSearchResultsProps): JSX.Element => {
  const searchResults: WGuestGroupSearchResult[] | string[] = useAppSelector(getRsvpSearchResults);
  const rsvpRequestEnabled = useAppSelector(getRsvpSearchEnabled);
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    state: {
      components: { styleCmsEntityBodyFont, ThemedButton },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const notFound = searchResults[0] === 'NOT_FOUND';
  const disabled = searchResults[0] === 'DISABLED';
  const validResults = searchResults.length > 0 && !notFound && !disabled;

  useEffect(() => {
    setErrorMsg(
      notFound
        ? "Hm... we can't find your name. Make sure you enter your name exactly as it appears on your invitation."
        : ''
    );
  }, [notFound, setErrorMsg]);

  useEffect(() => {
    if (!inPreview && searchResults.length > 0)
      scrollViewTo({ el: ref.current, behavior: 'smooth', offset: 100 });
  }, [searchResults, inPreview]);

  const StyledAbove = styleCmsEntityBodyFont(Above);
  const StyledBelow = styleCmsEntityBodyFont(Below);
  const StyledGuest = styleCmsEntityBodyFont(Guest);

  return (
    <Container ref={ref}>
      {validResults && (
        <PageAnimationWrapper
          animationProps={{
            direction: { wipeDirection: 'left', panDirection: 'left' },
          }}
        >
          <StyledAbove>Select your info below or try searching again.</StyledAbove>
          <ul>
            {formatGuestSearchResults(searchResults as WGuestGroupSearchResult[]).map(
              (guestGroup, rowI) => (
                <RsvpListRow key={`RsvpListRow--${rowI}`}>
                  <Guests>
                    {guestGroup?.guests?.map((guest, guestI) => (
                      <StyledGuest key={`StyledGuest--${guestI}`}>{formatGuest(guest)}</StyledGuest>
                    ))}
                  </Guests>
                  <ThemedButton onClick={() => onSelectGuest(guestGroup.uuid as string)}>
                    Select
                  </ThemedButton>
                </RsvpListRow>
              )
            )}
          </ul>
        </PageAnimationWrapper>
      )}
      {haveSearched && (
        <PageAnimationWrapper
          index={1}
          animationProps={{
            direction: { wipeDirection: 'right', panDirection: 'right' },
          }}
        >
          <BelowContainer>
            <>
              {validResults && (
                <StyledBelow>
                  If none of these are you, please reach out to the couple to see exactly how they
                  entered your details.
                </StyledBelow>
              )}
              {rsvpRequestEnabled && !searchDisabled && (
                <StyledBelow>
                  Still having trouble? Reach out to the couple and request access to their RSVP
                  page.
                </StyledBelow>
              )}
            </>
          </BelowContainer>
        </PageAnimationWrapper>
      )}
    </Container>
  );
};

export default GuestSearchResults;
