import type { WRsvpQuestionView, WRsvpAnswerView } from '@zola/svc-web-api-ts-client';
import type { FormattedRsvpAnswer } from '~/components/publicWebsiteV2/pages/Rsvp/types';

/* Keys need to be strings for final-form: https://github.com/final-form/react-final-form/blob/master/docs/faq.md#why-cant-i-have-numeric-keys-in-an-object */
/* we will destringify on submit */

const questionAccumulator = (acc: FormattedRsvpAnswer, obj: WRsvpQuestionView) => {
  acc[`key${obj.id}`] = { answer: '' };
  return acc;
};

const formatRsvpQuestionsAnswers = (
  questions: WRsvpQuestionView[],
  answers?: WRsvpAnswerView[]
): FormattedRsvpAnswer => {
  const rsvp_answers = questions.reduce(questionAccumulator, {});
  if (answers?.length)
    answers.forEach((answer) => {
      rsvp_answers[`key${answer.rsvp_question_id}`].answer = answer.answer || '';
    });
  return rsvp_answers;
};

export default formatRsvpQuestionsAnswers;
